<template>
  <v-container class="text-center">
    <v-btn
      :loading="loadingTransactionsBulkRequestFile"
      color="green"
      @click="processFile"
    >
      Procesar archivo adjuntado
    </v-btn>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { PRINCIPAL_WAREHOUSE } from '@/const'

  export default {
    name: 'CoreBulkLoad',
    props: {
      file: {
        type: File,
        default () {
          return undefined
        },
      },
    },
    computed: {
      ...mapState([
        'loadingTransactionsBulkRequestFile',
        'loadTransactionsBulkRequestResult',
        'actionWarehouse',
        'actionEnvironmentData',
        'actionsBusiness',
      ]),
      fileFormData () {
        const formData = new FormData()
        formData.set('file', this.file)
        formData.set('warehouseId', this.actionWarehouse)
        formData.set('relatedWarehouseId', PRINCIPAL_WAREHOUSE)
        formData.set('code', this.actionEnvironmentData.code)
        formData.set('sucursalId', this.actionsBusiness)

        return formData
      },
    },
    watch: {
      loadTransactionsBulkRequestResult (loadTransactionsBulkRequestResult) {
        if (loadTransactionsBulkRequestResult !== undefined) {
          if (loadTransactionsBulkRequestResult.result.ok === false) {
            this.$emit('setConsoleData', 'Error: ' + loadTransactionsBulkRequestResult.result.error.message)
          } else {
            this.setSuccess('Los datos se han cargado satisfactoriamente')
            this.$emit('setConsoleData', 'Los datos se han cargado satisfactoriamente')
          }
        }
      },
    },
    created () {
      this.$emit('disabledNext', true)
    },
    methods: {
      ...mapActions([
        'transactionsBulkLoadLoadFile',
        'setSuccess',
      ]),
      async processFile () {
        await this.transactionsBulkLoadLoadFile(this.fileFormData)
      },
    },
  }
</script>
